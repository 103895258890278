@import "vars";
@import "settings";
@import "base";

.g-link-abc {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.section {
    padding: 60px 0;
    color: #626366;
    background-color: #fff;
}

.section__title {
    text-transform: uppercase;
    font-size: 32px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 30px;
    color: #333;
    position: relative;
    padding-left: 30px;
}



.section__title::before,
.section__title::after{
    content: '';
    height: 55px;
    width: 5px;
    background-color: #0CB121;
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
}

.section__title::after{
    left: 10px;
}


.section__text{
    margin-bottom: 30px;
}

@media (max-width: 576px) {
    .section__title{
        font-size: 25px;
    }
}


@media (max-width: 992px) {
    .section {
        padding-top: 40px;
        padding-bottom: 20px;
    }
}
